import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import PatekCategoryBtns from '../../components/preowned/PatekCategoryBtns'

// markup
const PatekAnnualCalendar = () => {
  const data = useStaticQuery(
    graphql`
      query queryPatekPhilippe {
        products: allStrapiProduct(
          filter: { brand: { eq: "Patek Philippe" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Patek Philippe Watches for Sale'}
      canonical={'/fine-watches/patek-philippe/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Patek Philippe Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/patek-philippe/">
              <StaticImage
                src="../../images/preowned/preowned-patek-philippe-watches-banner.png"
                alt="Pre-Owned Certified Used Patek Philippe Watches Header"
                aria-label="Used Patek Philippe Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE PATEK PHILIPPE WATCHES AT
              GRAY AND SONS
            </h1>
            <h2>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED PATEK PHILIPPE WATCHES WITH GRAY AND
              SONS JEWELERS
            </h2>
            <p>
              We are a real watch and jewelry store located in Surfside, Miami. We're located across
              from the famous Bal Harbour Shops, with real watch and jewelry experts on-site, six
              days a week. We have a website but we're not a virtual store. Our customers always say
              we are Miami's #1 used watch buyer and seller. We've proudly assisted customers for
              the past 42 years. Gray and Sons are sellers and buyers of pre-owned and used and even
              new Patek Philippe watches in Miami, Miami Beach, Coral Gables, Coconut Grove, South
              Beach, Bal Harbour, Surfside, Sunny Isles , Aventura, Fort Lauderdale and many more
              areas. We offer certified pre-owned watches.
              <br />
              <br />
              Many people are saying, “I want to sell my watch for cash.”
              <a href="/">www.grayandsons.com</a> and{' '}
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com</a>
              will buy your used watch for cash. Gray and Sons is the best place to go for buying
              used Patek Philippe, selling Patek Philippe, and repairing Patek Philippe. About Patek
              Philippe Watches Founded in 1839, Patek Philippe is not only one of the last
              independent, family-owned Swiss watchmakers but also the finest timepiece brand in the
              market. Patek Philippe watches are ultra-luxurious, flawlessly combining intricately
              crafted mechanical movements with impeccable case and dial designs. Patek watches run
              the gamut from robust sports watches to classic dress watches to grand complication
              timepieces. However, regardless of the specific Patek Philippe model (whether men's
              Patek Philippe watches or women's Patek Philippe styles), what these luxury Swiss
              timepieces have in common is incredible quality, exquisite finishing, and precise
              timekeeping.
              <br />
              <br />
              Buying Pre-Owned Patek Philippe Watches The company has long used the slogan “You
              never actually own a Patek Philippe. You merely look after it for the next
              generation.” This declaration perfectly encapsulates what it means to buy a Patek
              Philippe watch. They will indeed last many lifetimes; purchasing a new or used Patek
              watch is, in essence, buying a family heirloom. There are many reasons people buy
              pre-owned Patek Philippe watches. Sometimes, secondhand Patek watches are less
              expensive and there are good deals to be had; however, since many Patek Philippe
              watches are known to hold their value, this isn't always the case. Purchasing a
              secondhand Patek also opens the doors to vintage and discontinued models. Finally, one
              of the main motivations for finding a pre-owned Patek in the secondary market is that
              they are sold out at retail. Gray &amp; Sons boasts an impressive collection of
              certified pre-owned Patek Philippe watches for sale.
              <br />
              <br />
              Popular Used Patek Philippe Models Pre-owned Nautilus and Aquanaut, Pre-owned Patek
              Annual Calendar, Perpetual Calendar, and Chronograph Pre-owned Calatrava, Gondolo,
              Golden Ellipse, and Twenty-4, Pre-owned Patek Grand Complications.
              <br />
              <br />
              Servicing Patek Philippe Watches Given their extraordinary craftsmanship and
              complicated movements, servicing a pre-owned Patek Philippe watch requires great skill
              and knowledge. As such, you should only trust an expert watchmaker to service or
              repair a Patek timepiece. Gray &amp; Sons is proud of its team of skilled watchmakers,
              each expertly trained to handle complex Swiss movements and high-end watches. Our
              Patek Philippe watch repairs and servicing facility is among the best in the business.
              You can come to Gray and Sons to replace your Patek Philippe watch battery. Many, many
              people near me say, “I want to sell my Patek Philippe watch for cash.” Selling to
              grayandsons.com and sellusyourjewelry.com are the best places to sell your Patek
              Philippe watch for cash. Would you like to <a href="/chat-with-rich">
                MAKE AN OFFER
              </a>{' '}
              on watches and jewelry? Vika and Rich are live expert decision makers and ready to
              work with you now. Reach out to Gray and Sons if you have any questions about our
              products and services. We are eager to help you find your next watch.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/patek-philippe/">
                <button>SHOP PATEK PHILIPPE WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PATEK PHILIPPE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <PatekCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PatekAnnualCalendar
